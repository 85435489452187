import React from 'react'

import * as st from '~/assets/styl/obrigado.module.styl'
import bauerLogo from '~/assets/img/bauer-turismo-red.png'

import { Link } from 'gatsby'

const Obrigado = () => (
  <>
    <div className={st.thankYouWrapper}>
      <img src={bauerLogo} alt="Logo da Bauer Turismo" />
      <section className={st.thankYou}>
        <h2>Mensagem enviada com sucesso!</h2>
        <p>
          Sua mensagem foi enviada para a nossa equipe de relacionamento. Em
          breve entraremos em contato para melhor atende-lo.
        </p>
        <Link to="/contato" className={st.back}>
          Voltar para página de contato
        </Link>
      </section>
    </div>
  </>
)

export default Obrigado
